.gpslandingpage {
  margin: 40px 20px;
}
.gpslandingpage_image {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
}
.noMargin {
  margin: 0 !important;
}
.trackercontainer {
  background-color: #eee;
}
.trackercontainerinside {
  padding-top: 60px;
  padding-bottom: 30px;
}
.hr-noline {
  border-color: transparent !important;
}
.services.tracker {
  border-color: #3cbefa;
}
.services {
  -webkit-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  transition-property: all;
  -webkit-box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 10%);
  box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 10%);
  -webkit-transition-property: -webkit-box-shadow;
  transition-property: -webkit-box-shadow;
  transition-property: box-shadow;
  transition-property: box-shadow, -webkit-box-shadow;
  margin-bottom: 3rem;
  position: relative;
  overflow: hidden;
  background-color: #fafafa;
  color: #848484;
  cursor: pointer;
  display: block;
  text-align: center;
  padding: 20px 30px;
  border-bottom: 8px solid transparent;
}
.services:hover {
  -webkit-box-shadow: 0 0 20px 0 rgb(0 0 0 / 20%);
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 20%);
  text-decoration: none;
}
.services .icon {
  padding: 10px;
  display: block;
  width: 57%;
  height: 387px;
  margin: 0 auto;
  margin-bottom: 30px;
  object-fit: contain;
}
.services.fleet {
  border-color: #ffa200;
}
.services.tracker h5 {
  color: #3cbefa;
}
.services.fleet h5 {
  color: #ffa200;
}
.login-footer {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  color: #ffffff;
  padding-top: 75px;
  padding-bottom: 75px;
}
.font-weight-thin {
  font-weight: 100;
}
@media screen and (max-width: 768px) {
  .mobilemargintop {
    margin-top: 30px !important;
  }
}
@media screen and (max-width: 361px) {
  .imagemobileresponsive {
    margin-left: -8px !important;
    /* margin-top: 20px !important; */
  }
}
@media screen and (max-width: 356px) {
  .imagemobileresponsive {
    margin-left: -8px !important;
    margin-top: 20px !important;
  }
}
.imagearrow {
  width: 50px;
  font-size: 46px;
}
.imagecontainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
